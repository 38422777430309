.credit_header {
  padding: 0 16px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .avatar-circle {
    background-color: #c37878;
    cursor: pointer;
  }
}
.dropmenu-item {
  padding: 20px 10px;
}

.card-container p {
  margin: 0;
}

.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: #fafafa;
  border-color: #fafafa;
  border: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #ffffff;
  border-color: #ffffff;
  border: none;
}
.card-container > .ant-tabs-card > .ant-tabs-nav {
  margin-bottom: 0;
}

@primary-color: #1DA57A;