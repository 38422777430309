.credit-list-page {
  .tools-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;

    .filter-item {
      background-color: #ffffff;
      min-width: 290px;
      padding: 0 8px;
      .ant-input {
        border: none;
        box-shadow: none;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        box-shadow: none;
      }
      .ant-form-item-label > label {
        color: #2d3843;
        font-weight: bold;
      }
      .ant-picker {
        border: none;
        box-shadow: none;
      }
    }
  }
}

@primary-color: #1DA57A;