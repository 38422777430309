.company-list-page {
  .tools-wrapper {
    margin: 8px 0 24px 0;
    display: flex;
    justify-content: space-between;
    .filter-item {
      background-color: #ffffff;
      width: 200px;
      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button {
        border: none;
        box-shadow: none;
      }
      .ant-input {
        border: none;
        box-shadow: none;
      }
    }
  }
  .table-container {
    background-color: #ffffff;
  }
}

.company-apply-list-page {
  .tools-wrapper {
    display: flex;
    justify-content: space-between;
    .company-apply-list-filter-input-container {
      form {
        margin-bottom: 16px;
      }
      .filter-item {
        background-color: #ffffff;
        min-width: 290px;
        padding: 0 8px;
        .ant-input {
          border: none;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: none;
          box-shadow: none;
        }
        .ant-form-item-label > label {
          color: #2d3843;
          font-weight: bold;
        }
        .ant-picker {
          border: none;
          box-shadow: none;
        }
        .ant-input {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

@primary-color: #1DA57A;