.submit-credit-page {
  background-color: #ffffff;
  padding: 16px;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@primary-color: #1DA57A;