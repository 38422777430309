.home {
  min-height: 100vh;

  .page_container {
    min-width: 1200px;
    height: 100vh;
    .page_content {
      margin: 16px 16px;
      height: 100%;
      overflow-y: auto;
    }
  }
  .home_footer {
    padding: 0 32px 16px 32px;
    display: flex;
    justify-content: center;
  }
}

@primary-color: #1DA57A;