@import "~antd/es/style/themes/default.less";
.workbench-page__sidebar {
  height: 100vh;
}

.action {
  position: absolute;
  bottom: 0;
  background-color: #000c17;
  width: 100%;
  .info-container {
    width: 100%;
    display: flex;
    padding-left: 12px;
    align-items: center;
    cursor: pointer;
    .sider-username {
      color: #ffffffa6;
      padding: 0 8px;
      font-size: 14px;
      :hover {
        color: #ffffff;
      }
    }
  }
  .action-container {
    display: flex;
    width: 100%;
    align-items: center;

    .action-btn {
      color: #ffffffa6;
      width: 49%;
      text-align: center;
      cursor: pointer;
      :hover {
        color: #ffffff;
      }
    }
  }
}
.collapsed-avatar {
  position: absolute;
  bottom: 0;
}

.ant-layout-sider .logo {
  height: @layout-header-height;
  box-sizing: border-box;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  > img.expanded {
    max-height: 100%;
    width: 180px;
  }

  > img.collapsed {
    max-height: 100%;
    width: 40px;
  }
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: visible;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: linear-gradient(90deg, #1da57a, #2f3b46);
  opacity: 0.7;
  background-color: #2f3b46;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 8px;
  margin-bottom: 8px;
}

@primary-color: #1DA57A;