.quota-list-page {
  .tools-wrapper {
    margin: 8px 0 24px 0;
    display: flex;
    justify-content: space-between;
    .filter-item {
      background-color: #ffffff;
      width: 200px;
      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button {
        border: none;
        box-shadow: none;
      }
      .ant-input {
        border: none;
        box-shadow: none;
      }
    }
  }
  .table-container {
    background-color: #ffffff;
  }
}

@primary-color: #1DA57A;